import * as React from 'react'
import Quality from './images/all_features/quality.png'
import FrameRate from './images/all_features/frame-rate.png'
import ColorMode from './images/all_features/color-mode.png'
import Resolution from './images/all_features/resolution.png'
import RemoteAccess from './images/all_features/remote-access.png'
import FileTransfer from './images/all_features/file-transfer.png'
import Clipboard from './images/all_features/clipboard.png'
import FileDragDrop from './images/all_features/file-drag-drop.png'
import MultiMonitorSupport from './images/all_features/multi-monitor-support.png'
import VirtualScreen from './images/all_features/virtual-screen.png'
import UnattendedAccess from './images/all_features/unattended-access.png'
import MultiScreenPreview from './images/all_features/multi-screen-preview.png'
import WakeOnLan from './images/all_features/wake-on-lan.png'
import RemoteShutdown from './images/all_features/remote-shutdown.png'
import RemoteRestart from './images/all_features/remote-restart.png'
import RemoteLockScreen from './images/all_features/remote-lock-screen.png'
import GameKeyboard from './images/all_features/game-keyboard.png'
import MultitouchMode from './images/all_features/multitouch-mode.png'
import ThreeDGamingMode from './images/all_features/3d-gaming-mouse.png'
import MouseLock from './images/all_features/mouse-lock.png'
import ScreenRecording from './images/all_features/screen-recording.png'
import TextChat from './images/all_features/text-chat.png'
import CtrlAltDel from './images/all_features/ctrl-alt-del.png'
import TaskManager from './images/all_features/task-manager.png'
import VoiceSync from './images/all_features/voice-sync.png'
import Digitizer from './images/all_features/digitizer.png'
import Gamepad from './images/all_features/gamepad.png'
import PrivacyMode from './images/all_features/privacy-mode.png'
import TrustedDevice from './images/all_features/trusted-device.png'
import SessionHistory from './images/all_features/session-history.png'
import DeviceList from './images/all_features/device-list.png'
import AutoUpdate from './images/all_features/auto-update.png'
import OrangeArrow from './images/orange-arrow'
import PurpleArrow from './images/purple-arrow'
import BlueArrow from './images/blue-arrow'
import Path from '@raylink-overseas/common/constants/path'

type ItemProps = {
  title: string
  description: string
  icon: string
  hasLink?: boolean
  link?: string
  arrow?: React.ReactNode
}
export type FeaturesCardProps = {
  title: string
  description: string
  bgcolor: string
  hovercolor: string
  shadows: string
  itemList: ItemProps[]
  bgFrom: string
  bgTo: string
  bgDeg?: number
}

export const nav: string[] = [
  'Image Clarity',
  'Desktop Control',
  'External Devices',
  'Security & Privacy',
]

export const FeaturesData: FeaturesCardProps[] = [
  {
    title: nav[0],
    description:
      'Personalize the remote visual experience with customizable settings.',
    bgcolor: 'rgba(255, 249, 243, 1)',
    hovercolor: 'linear-gradient(106.38deg, #FFF9F7 0%, #FFFFFF 100%)',
    shadows: '8px 8px 16px 0px rgba(255, 137, 86, 0.05)',
    bgFrom: '#FF8956',
    bgTo: 'rgba(255, 137, 86, 0.3)',
    bgDeg: 107.78,
    itemList: [
      {
        title: 'Quality',
        description:
          'Customize the clarity of remote visuals with options for smooth, HD, Ultra HD, etc.',
        icon: Quality,
      },
      {
        title: 'Frame Rate',
        description:
          'Customize frame display for fluid remote visuals, with up to 144FPS.',
        icon: FrameRate,
      },
      {
        title: 'Color Mode',
        description:
          'Support 4:2:0 and 4:4:4 Color Modes to meet requirements for picture color reproduction.',
        icon: ColorMode,
        hasLink: true,
        link: '/help-center/4-4-4-color/',
        arrow: <OrangeArrow />,
      },
      {
        title: 'Resolution',
        description:
          'Support for adjusting screen resolution of the remote device.',
        icon: Resolution,
      },
    ],
  },
  {
    title: nav[1],
    description:
      'Use various advanced features to enhance the efficiency of remote access and control.',
    bgcolor: 'rgba(243, 247, 255, 1)',
    hovercolor: 'linear-gradient(108.4deg, #F3F7FF 0%, #FFFFFF 100%)',
    shadows: '8px 8px 15px 0px rgba(107, 128, 254, 0.05)',
    bgFrom: '#6B80FE',
    bgTo: 'rgba(107, 128, 254, 0.3)',
    itemList: [
      {
        title: 'Remote Access',
        description:
          'Remotely access the device at any time and have full control over it.',
        icon: RemoteAccess,
        hasLink: true,
        link: Path.remoteAccess,
        arrow: <PurpleArrow />,
      },
      {
        title: 'File Transfer',
        description:
          'Fast file transfer at any size and any format, with no quantity limitations.',
        icon: FileTransfer,
        hasLink: true,
        link: Path.fileTransfer,
        arrow: <PurpleArrow />,
      },
      {
        title: 'Clipboard',
        description: 'Enable copy-paste for text and files between devices.',
        icon: Clipboard,
        hasLink: true,
        link: '/help-center/file-transfer/',
        arrow: <PurpleArrow />,
      },
      {
        title: 'File Drag and Drop',
        description:
          'Transfer files through a simple drag-and-drop method between devices.',
        icon: FileDragDrop,
        hasLink: true,
        link: '/help-center/file-transfer/',
        arrow: <PurpleArrow />,
      },
      {
        title: 'Multi-Monitor Support',
        description:
          'View and work with multiple monitors from a remote computer on the local device.',
        icon: MultiMonitorSupport,
        hasLink: true,
        link: Path.multiMonitorSupport,
        arrow: <PurpleArrow />,
      },
      {
        title: 'Virtual Screen',
        description:
          'Create multiple virtual screens on the remote device for aditional displays.',
        icon: VirtualScreen,
      },
      {
        title: 'Unattended Access',
        description: 'Connect to the remote device when no user is present.',
        icon: UnattendedAccess,
        hasLink: true,
        link: Path.unattendedAccess,
        arrow: <PurpleArrow />,
      },
      {
        title: 'Multi-Screen Preview',
        description: `Support monitor different devices' screens on the previewer.`,
        icon: MultiScreenPreview,
      },
      {
        title: 'Wake-On-LAN',
        description: 'Remotely power up the offline device in the LAN.',
        icon: WakeOnLan,
        hasLink: true,
        link: '/help-center/wake-on-lan/',
        arrow: <PurpleArrow />,
      },
      {
        title: 'Remote Shutdown',
        description:
          'Support shutting down the remote device from any location.',
        icon: RemoteShutdown,
      },
      {
        title: 'Remote Restart',
        description:
          'Support rebooting the remote device without any physical interaction.',
        icon: RemoteRestart,
      },
      {
        title: 'Remote Lock Screen',
        description: 'Support locking the screen of the remote  device.',
        icon: RemoteLockScreen,
      },
      {
        title: 'Game Keyboard',
        description:
          'Customize game keyboard mapping for remote PC control on mobile.',
        icon: GameKeyboard,
      },
      {
        title: 'Multitouch Mode',
        description:
          'Support multiple simultaneous touchpoints on the local mobile device.',
        icon: MultitouchMode,
      },
      {
        title: '3D Gaming Mouse',
        description:
          'Move, rotate and zoom the view during the remote 3D gaming.',
        icon: ThreeDGamingMode,
      },
      {
        title: 'Mouse Lock',
        description:
          'Lock the mouse to avoid accidental interactions outside the remote interface.',
        icon: MouseLock,
      },
      {
        title: 'Screen Recording',
        description:
          'Record and save the remote session activities in specified location.',
        icon: ScreenRecording,
        hasLink: true,
        link: '/help-center/screen-recording/',
        arrow: <PurpleArrow />,
      },
      {
        title: 'Text Chat',
        description: 'Chat via text between devices connected remotely.',
        icon: TextChat,
      },
      {
        title: 'Ctrl+Alt+Del',
        description:
          'Quickly enable the Ctrl+Alt+Del shortcut key on the remote device.',
        icon: CtrlAltDel,
      },
      {
        title: 'Task Manager',
        description: 'Quickly open the Task Manager of the remote device.',
        icon: TaskManager,
      },
      {
        title: 'Voice Sync',
        description: 'Sync voice from the local device to the remote device.',
        icon: VoiceSync,
      },
    ],
  },
  {
    title: nav[2],
    description: 'Connect external devices for assisted remote operations.',
    bgcolor: 'rgba(243, 255, 245, 1)',
    hovercolor: 'linear-gradient(108.4deg, #F3FFF5 0%, #FFFFFF 100%)',
    shadows: '8px 8px 15px 0px rgba(43, 200, 163, 0.05)',
    bgFrom: '#2BC8A3',
    bgTo: 'rgba(43, 200, 163, 0.3)',
    itemList: [
      {
        title: 'Digitizer',
        description:
          'External graphics tablet supported, remote responsive to pressure and levelness.',
        icon: Digitizer,
      },
      {
        title: 'Gamepad',
        description:
          'Allow simultaneous connection of up to 4 game controllers.',
        icon: Gamepad,
      },
    ],
  },
  {
    title: nav[3],
    description:
      'Configure security and privacy features to enhance remote security.',
    bgcolor: 'rgba(240, 248, 255, 1)',
    hovercolor: 'linear-gradient(108.4deg, #F0F8FF 0%, #FFFFFF 100%)',
    shadows: '8px 8px 16px 0px rgba(49, 151, 246, 0.05)',
    bgFrom: '#3197F6',
    bgTo: 'rgba(49, 151, 246, 0.3)',
    itemList: [
      {
        title: 'Privacy Mode',
        description:
          'Block others from seeing and hearing your activities from the host device.',
        icon: PrivacyMode,
        hasLink: true,
        link: Path.privacyMode,
        arrow: <BlueArrow />,
      },
      {
        title: 'Trusted Device',
        description:
          'Only trusted devices authed by you can log in to your Avica account.',
        icon: TrustedDevice,
      },
      {
        title: 'Session History',
        description:
          'Keep a record of remote session logs with support for viewing and searching.',
        icon: SessionHistory,
      },
      {
        title: 'Device List',
        description:
          'Add unlimited devices to the lists and manage devices in groups.',
        icon: DeviceList,
        hasLink: true,
        link: '/help-center/how-to-add-or-remove-devices/',
        arrow: <BlueArrow />,
      },
      {
        title: 'Auto Update',
        description:
          'Enable or disable automatic update mode according to your needs.',
        icon: AutoUpdate,
      },
    ],
  },
]
