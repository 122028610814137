import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import { useMedia } from '@raylink-overseas/common/hooks'
import Banner from './components/Banner'
import AllFeatureCard from './components/AllFeatureCard'
import StartNow from './components/StartNow'

const Features = () => {
  const isMedia = useMedia()
  const handleScroll = (index: number, distance: number) => {
    const target = document.getElementById(`features-${index}`) as HTMLElement
    const topPosition =
      target.getBoundingClientRect().top + window.scrollY - distance
    window.scrollTo({
      top: topPosition,
      behavior: 'smooth',
    })
  }
  return (
    <Layout pageType="features">
      <Banner scrollTo={handleScroll} />
      <AllFeatureCard />
      <StartNow
        pre="Start Your"
        span={!isMedia ? 'Remote Connection' : 'Remote Desktop Access'}
        suf="Now"
      />
    </Layout>
  )
}

export default Features
