import React, { useEffect, useRef, useState } from 'react'
import { Banner, Title, Tip, NavList, BannerContent } from './atoms'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import AvailableList from '@raylink-overseas/common/components/availableList'
import { nav } from '../../constant'
import { useMedia } from '@raylink-overseas/common/hooks'
import Path from '@raylink-overseas/common/constants/path'

type FeaturesBannerProps = {
  scrollTo: (index: number, distance: number) => void
}
const FeaturesBanner = ({ scrollTo }: FeaturesBannerProps) => {
  const isMedia = useMedia()
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const [stickyTop, setStickyTop] = useState<number>(68)
  const [currentActive, setCurrentActive] = React.useState<number>(0)
  const bannerRef = useRef<HTMLDivElement>(null)
  const navRef = useRef<HTMLDivElement>(null)

  const getAllFeatures = () => {
    const allFeatures = document.querySelectorAll('.features-module')
    const allFeaturesArr = Array.from(allFeatures)
    return allFeaturesArr
  }
  const handleScroll = () => {
    if (navRef.current) {
      setIsSticky(navRef.current.getBoundingClientRect().top <= stickyTop)
    }
    if (bannerRef.current) {
      const bannerBottom = bannerRef.current.getBoundingClientRect().bottom
      setIsSticky(bannerBottom <= 92 + stickyTop)
    }
    handleHeaderChange()
    // 处理页面滚动 自动切换nav
    const sections = getAllFeatures()
    const buttons = document.querySelectorAll('.nav-item')
    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect()
      if (rect.top >= 0 && rect.top < 102 + stickyTop) {
        buttons.forEach(button => button.classList.remove('active'))
        buttons[index].classList.add('active')
      }
    })
  }
  const handleHeaderChange = () => {
    // 广告被关闭后
    const header = document.getElementById('header-box')
    setStickyTop(header!.clientHeight)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // header有变更（关闭通知公告或顶部横幅），重新计算header的高度
      const callback = (mutationList: MutationRecord[]) => {
        mutationList.forEach(mutation => {
          switch (mutation.type) {
            // 从树上添加或移除一个或更多的子节点时触发
            case 'childList':
              handleHeaderChange()
              break
            default:
              break
          }
        })
      }
      const headerEle = document.getElementById('header-box')
      const observerOptions = {
        childList: true, // 观察目标子节点的变化，是否有添加或者删除
        attributes: false, // 观察属性变动
      }
      const observer = new MutationObserver(callback) // 监听某个DOM元素变化
      observer.observe(headerEle!, observerOptions)

      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const scrollDom = (index: number) => {
    setCurrentActive(index)
    const distance = (isMedia ? 68 : 92) + stickyTop
    scrollTo(index, distance)
  }
  return (
    <Banner ref={bannerRef}>
      <BannerContent>
        <Title>Explore All Features for Avica</Title>
        <Tip>
          Maximize your remote desktop experience with full Avica power
          unleashed.
        </Tip>
        <ButtonList
          className="btns"
          btnInfo={[
            { text: 'Free Download', href: Path.downloads },
            { text: 'See Pricing', href: Path.pricing },
          ]}
        />
        <AvailableList />
      </BannerContent>
      <NavList
        ref={navRef}
        className={isSticky ? 'sticky' : ''}
        stickyTop={stickyTop}
      >
        {nav.map((item, index) => {
          return (
            <span
              onClick={() => scrollDom(index)}
              className={`nav-item ${currentActive === index ? 'active' : ''}`}
              key={index}
            >
              {item}
            </span>
          )
        })}
      </NavList>
    </Banner>
  )
}

export default FeaturesBanner
