import React from 'react'
import { StartNowWrap, StartNowContent } from './atoms'
import Logo from '@svg/logo.svg'
import { Button } from '@raylink-overseas/common/components'
import FreeDownload from '@raylink-overseas/common/components/freeDownload'
import useHref from '@raylink-overseas/common/hooks/use-href'
import Path from '@raylink-overseas/common/constants/path'

type btnProps = {
  pre: string
  span: string
  suf: string
}

const StartNow = ({ pre, span, suf }: btnProps) => {
  const paramsHref = useHref()
  return (
    <StartNowWrap>
      <StartNowContent>
        <Logo />
        <h2 className="start">
          {pre} <span>{span}</span> {suf}
        </h2>
        <div className="btns">
          <FreeDownload className="start-download" />
          <Button
            className="pricing"
            as="a"
            href={paramsHref(Path.pricing)}
            target="_blank"
            rel="noreferrer nofollow"
          >
            See Pricing
          </Button>
        </div>
      </StartNowContent>
    </StartNowWrap>
  )
}
export default StartNow
