import styled from 'styled-components'
import FeaturesBg from '../../images/features-bg.png'
import { Media, color } from '@raylink-overseas/common/theme'

export const Banner = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  min-width: 1400px;
  height: 480px;
  margin: auto;
  background: url(${FeaturesBg}) top center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Media.homeLaptop`
    min-width: 1184px;
    height: 500px;
  `}
  ${Media.laptop`
    min-width: 1088px;
    height: 500px;
  `}
  ${Media.ipad`
    height: 480px; 
    min-width: 896px;
  `}
  ${Media.phone`
    min-width: 100%;
    height: 8rem;
  `}
`
export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btns {
    margin-top: 40px;
  }
`
export const Title = styled.h1`
  font-size: 44px;
  font-weight: 800;
  line-height: 50px;
  text-align: center;
  margin-bottom: 8px;
  ${Media.phone`
    width: 6.86rem;
    font-size: 0.52rem;
    line-height: 0.6rem;
  `}
`
export const Tip = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${color.textDark};
  ${Media.phone`
    width: 5.96rem;
    font-size: 0.28rem;
    line-height: 0.36rem;
    text-align: center;
  `}
`

export const NavList = styled.div<{ stickyTop: number }>`
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 64px;
  width: 100%;
  background: linear-gradient(
      90deg,
      rgba(244, 237, 253, 0.8) 0%,
      rgba(219, 230, 254, 0.8) 51%,
      rgba(219, 230, 254, 0.8) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  backdrop-filter: blur(8px);
  &::-webkit-scrollbar {
    display: none;
  }
  span {
    width: 222px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    background: ${color.white};
    border-radius: 20px;
    padding: 0 20px;
    cursor: pointer;
    font-weight: 400;
    &:hover {
      color: ${color.primary};
    }
    &.active {
      color: ${color.primary};
      font-weight: 500;
      font-size: 18px;
    }
  }
  &.sticky {
    position: fixed;
    top: ${({ stickyTop }) => `${stickyTop}px`};
  }
  ${Media.phone<{ stickyTop: number }>`
    padding: 0 0.32rem;
    gap: 0.16rem;
    overflow-x: auto;
    justify-content: flex-start;
    span {
      width: unset;
      font-size: 0.28rem;
      width: auto;
      white-space: nowrap;
      &.active {
        font-size: 0.28rem;
      }
    }
    /* &.sticky {
      top: ${({ stickyTop }) => `${(stickyTop * 2) / 100}rem`};
    } */
  `}
`
