import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

interface CardItemProps {
  bgFrom?: string
  bgTo?: string
  bgColor: string
  hoverColor: string
  shadows: string
  hasLink?: boolean
  bgDeg: number
}

export const AllCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;
  ${Media.homeLaptop`
    min-width: 1184px;
  `}
  ${Media.laptop`
    min-width: 1088px;
  `}
  ${Media.ipad`
    min-width: 896px;
  `}
  ${Media.phone`
    width: 100%;
    min-width: 6.86rem;
    margin-bottom: 0.76rem;
  `}
`

export const CardWrap = styled(BigWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  gap: 32px;
  ${Media.homeLaptop`
    width: 1184px;
    min-width: 1184px;
  `}
  ${Media.laptop`
    width: 1088px;
    min-width: 1088px;
  `}
  ${Media.ipad`
    width: 896px;
    min-width: 896px;
    gap: 24px;
    .btn {
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 18px;
      color: #445FFF;
      gap: 6px;
      span {
        display: flex;
        align-items: center;
      }
      .arrow {
        display: flex;
      }
    }
  `}
  ${Media.phone`
    width: 100%;
    min-width: auto;
    gap: 0.32rem;
    margin: 0.76rem 0 0;
    align-items: center;
    .btn {
      display: flex;
      justify-content: center;
      font-size: 0.28rem;
      line-height: 0.36rem;
      color: #445FFF;
      gap: 0.12rem;
      span {
        display: flex;
        align-items: center;
      }
    }
  `}
`
export const Head = styled.div`
  display: flex;
  align-items: end;
  gap: 12px;
  width: 100%;
  .title {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  }
  .desc {
    font-size: 18px;
    line-height: 32px;
    color: #595959;
  }
  ${Media.ipad`
    flex-direction: column;
    align-items: start;
    gap: 0;
    .title {
      font-size: 30px;
      line-height: 30px;
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
    }
  `}
  ${Media.phone`
    width: 6.86rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.12rem;
    .title {
      font-size: 0.48rem;
      line-height: 0.6rem;
    }
    .desc {
      width: 100%;
      font-size: 0.28rem;
      line-height: 0.36rem;
    }
  `}
`
export const Bottom = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  gap: 24px;
  width: 100%;
  ${Media.ipad`
    gap: 16px;
  `}
  ${Media.phone`
    width: 6.86rem;
    gap: 0.32rem;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
    justify-content: center;
  `}
`
export const CardItem = styled.div<CardItemProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: ${({ bgColor }) => bgColor};
  padding: 32px 32px 40px;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    &::before {
      content: '';
      position: absolute;
      inset: -2px;
      border-radius: 8px;
      background: ${({ bgFrom, bgTo, bgDeg }) =>
        `linear-gradient(${bgDeg}deg, ${bgFrom} 0%, ${bgTo} 100%)`};
      z-index: -1;
    }
  }
  .item-head {
    display: flex;
    align-items: center;
    gap: 8px;
    .item-icon {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
    }
  }
  .item-desc {
    font-size: 16px;
    line-height: 20px;
    color: #595959;
  }
  .redirect {
    position: absolute;
    bottom: 22px;
    right: 32px;
    transition: transform 0.3s ease;
  }
  ${({ hoverColor, shadows }) =>
    `
    &:hover {
      background: ${hoverColor};
      box-shadow: ${shadows};
      .redirect {
        transform: translateX(10px);
      }
    }
  `}
  ${Media.laptop`
    width: 260px;
    .item-head {
      align-items: start;
    }
  `}
  ${Media.ipad`
    width: 212px;
    .item-head {
      flex-direction: column;
      .item-title {
        font-size: 18px;
        line-height: 23px;
      }
    }
    .item-desc {
      width: 164px;
    }
    .redirect {
      position: absolute;
      bottom: 20px;
      right: 24px;
    }
  `}
  ${Media.phone`
    width: unset;
    padding: 0.32rem 0.4rem 0.4rem 0.48rem;
    gap: 0.16rem;
    height: fit-content;
    .item-head {
      flex-direction: row;
      align-items: center;
      .item-title {
        white-space: nowrap;
        font-size: 0.36rem;
        line-height: 0.46rem;
      }
    }
    .item-desc {
      width: unset;
      font-size: 0.28rem;
      line-height: 0.36rem;
    }
    ${({ hasLink }: CardItemProps) =>
      hasLink &&
      `
        padding: 0.32rem 0.4rem 0.68rem 0.48rem;
        .redirect {
          bottom: 0.2rem;
          right: 0.48rem;
        }
      `}
  `}
`
