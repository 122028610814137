import React, { useState } from 'react'
import { useMedia } from '@raylink-overseas/common/hooks'
import { AllCardWrap, CardWrap, Head, Bottom, CardItem } from './atoms'
import { FeaturesData } from '../../constant'
import DownArrow from '../../images/down-arrow.svg'
import UpArrow from '../../images/up-arrow.svg'
import { jumpTo } from '@raylink-overseas/common/utils'

const AllFeatureCard = () => {
  const isMobile = useMedia()
  const isIpad = useMedia({ maxWidth: '1266px' })
  const CARD_COUNT = isMobile ? 5 : 4
  const [visibleCount, setVisibleCount] = useState<number>(CARD_COUNT)
  const handleViewMore = () => {
    setVisibleCount(visibleCount => visibleCount + CARD_COUNT)
  }
  const handleViewLess = () => {
    setVisibleCount(CARD_COUNT)
  }

  const viewToggleButton = (isViewMore: boolean, handleClick: () => void) => (
    <div className="btn" onClick={handleClick}>
      <span>{isViewMore ? 'View More' : 'View Less'}</span>
      <img src={isViewMore ? DownArrow : UpArrow} alt="" />
    </div>
  )
  return (
    <AllCardWrap>
      {FeaturesData.map((feature, index) => {
        const visibleItems =
          isIpad && feature.itemList.length > 5
            ? feature.itemList.slice(0, visibleCount)
            : feature.itemList
        return (
          <CardWrap
            id={`features-${index}`}
            key={feature.title}
            className="features-module"
          >
            <Head>
              <h2 className="title">{feature.title}</h2>
              <div className="desc">{feature.description}</div>
            </Head>
            <Bottom>
              {visibleItems.map(item => (
                <CardItem
                  key={item.title}
                  bgFrom={feature.bgFrom}
                  bgTo={feature.bgTo}
                  bgColor={feature.bgcolor}
                  bgDeg={feature.bgDeg || 108.4}
                  hoverColor={feature.hovercolor}
                  shadows={feature.shadows}
                  hasLink={item.hasLink}
                  onClick={() => item.hasLink && jumpTo(item.link || '')}
                >
                  <div className="item-head">
                    <div className="item-icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="item-title">{item.title}</div>
                  </div>
                  <div className="item-desc">{item.description}</div>
                  {item.hasLink && <div className="redirect">{item.arrow}</div>}
                </CardItem>
              ))}
            </Bottom>
            {isIpad &&
              feature.itemList.length > 5 &&
              (visibleCount < feature.itemList.length
                ? viewToggleButton(true, handleViewMore)
                : viewToggleButton(false, handleViewLess))}
          </CardWrap>
        )
      })}
    </AllCardWrap>
  )
}
export default AllFeatureCard
